<template>
	<div>
		<div class="card mb-0">
			<div class="mx-2 m-2 position-relative">
				<b-row>
					<b-col
						cols="12"
						md="12"
						lg="4"
						class="d-flex align-items-center justify-content-start justify-content-sm-start"
					>
						<span class="text-muted">
							Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
						</span>
					</b-col>
					<!-- Pagination -->
					<!-- {{current_page}} -->
					<b-col cols="12" md="12" lg="8" class="flex-nowrap">
						<div class="flex flex-column align-items-end">
							<div class="xt-search-input__paginate mt-1 mt-md-0">
								<b-pagination
									v-model="current_page"
									:total-rows="total_data"
									:per-page="perpage"
									first-number
									last-number
									prev-class="prev-item"
									next-class="next-item"
								>
									<template #prev-text>
										<feather-icon icon="ChevronLeftIcon" size="18" />
									</template>
									<template #next-text>
										<feather-icon icon="ChevronRightIcon" size="18" />
									</template>
								</b-pagination>
							</div>
							<div class="xt-search-input__search">
								<b-form-input
									type="search"
									class="mr-md-1 w-100"
									@keyup.enter="init"
									v-model="search"
									placeholder="Codigo"
									style="width: 200px"
								/>
								<div class="search-btn mt-1 mt-md-0">
									<button @click="init" class="btn btn-primary w-100">Buscar</button>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
			</div>

			<div class="table-responsive mb-0">
				<b-table-simple sticky-header class="table mb-0" :style="{ minHeight: '70vh' }">
					<b-thead>
						<b-tr>
							<b-th colspan="2" class="bg-transparent border-0"> </b-th>
							<!-- <b-th colspan="3" class="text-center bg-white">
								<div class="bg-info">
									<p class="text-white font-weight-bolder mb-0">Fechas</p>
								</div>
								
							</b-th> -->

							<b-th colspan="3" class="p-25 bg-info bg-darken-1 text-white text-center">
								<div class="">
									<p class="mb-0">Fechas</p>
								</div>
							</b-th>
							<b-th colspan="4" class="bg-transparent border-0"> </b-th>
						</b-tr>

						<!-- {{headTable}} -->
						<b-tr>
							<template v-for="(title, key) in headTable">
								<b-th :key="key" v-if="title.key != 'status' ? true : showStatus">
									<!-- {{title.visible}} -->
									<template>
										<div class="d-flex align-items-center justify-content-center cursor-pointer">
											<p class="mb-0 mr-1">{{ title.title }}</p>
										</div>
									</template>
								</b-th>
							</template>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr class="text-center" v-for="(item, index) in orders" :key="index">
							<template v-if="orders.length > 0">
								<b-td class="">
									<p
										@click="item.status_id == 6 && isSupervisor && redirectCart(item.id)"
										class="mb-0"
										:class="[
											item.status_id == 6 && isSupervisor
												? 'font-weight-bolder text-primary cursor-pointer'
												: '',
										]"
									>
										{{ item.code }}
									</p>
								</b-td>

								<b-td class="">
									<p class="mb-0">{{ item.observation }}</p>
								</b-td>
								<b-td class="">
									<p v-if="item.date_request" class="mb-0">
										{{ item.date_request | myGlobalDayShort }}
									</p>
									<p v-else class="mb-0">{{ "---" }}</p>
								</b-td>

								<b-td class="">
									<p v-if="item.reference_day" class="mb-0">
										{{ item.reference_day | myGlobalDayShort }}
									</p>
									<p v-else class="mb-0">{{ "---" }}</p>
								</b-td>

								<b-td class="">
									<p v-if="item.date_delivered" class="mb-0">
										{{ item.date_delivered | myGlobalDayShort }}
									</p>
									<p v-else class="mb-0">{{ "---" }}</p>
								</b-td>

								<b-td class="">
									<p class="mb-0">{{ Number.parseFloat(item.total_s).toFixed(2) }}</p>
								</b-td>
								<b-td class="">
									<p class="mb-0">{{ Number.parseFloat(item.total_d).toFixed(2) }}</p>
								</b-td>

								<b-td class=" " v-if="showStatus">
									<p class="mb-0">{{ textStatus(item.status_id) || "Sin estado" }}</p>
								</b-td>

								<b-td class="">
									<p class="mb-0">{{ item.created.user }}</p>
									<span>{{ item.created.date | myGlobalDayShort }}</span>
								</b-td>

								<b-td class="">
									<div class="d-flex justify-content-center">
										<FeatherIcon
											v-b-tooltip.hover
											title="Ver detalle"
											@click="
												viewModalShowById.show = true;
												viewModalShowById.data = item;
											"
											icon="ShoppingBagIcon"
											size="20"
											class="mr-50 cursor-pointer icon-grid"
										/>

										<FeatherIcon
											v-b-tooltip.hover
											title="Ver seguimiento"
											@click="viewModalTrackingFunction(item)"
											v-if="status != 1"
											icon="BookOpenIcon"
											size="20"
											class="cursor-pointer mr-50 icon-grid"
										/>

										<FeatherIcon
											v-if="isAdministrator && [1, 2].includes(status)"
											@click="
												viewModalChangeStatus.show = true;
												viewModalChangeStatus.order = item;
												viewModalChangeStatus.status = status + 1;
												viewModalChangeStatus.title =
													item.code + '(' + (status + 1 == 2 ? 'Procesar' : 'Entregar') + ')';
											"
											v-b-tooltip.hover
											:title="status + 1 == 2 ? 'Procesar' : 'Entregar'"
											icon="ArrowRightCircleIcon"
											size="20"
											class="cursor-pointer icon-grid"
											:class="[status + 1 == 2 ? '' : '']"
										/>

										<FeatherIcon
											@click="
												viewModalChangeStatus.show = true;
												viewModalChangeStatus.order = item;
												viewModalChangeStatus.status = 4;
												viewModalChangeStatus.title = item.code + '(Anular)';
											"
											v-b-tooltip.hover
											title="Anular"
											v-if="![3, 4].includes(status)"
											icon="TrashIcon"
											size="20"
											class="text-danger cursor-pointer ml-50 icon-grid"
										/>

										<template v-if="item.status_id == 5">
											<FeatherIcon
												v-b-tooltip.hover
												title="Confirmado"
												icon="CheckSquareIcon"
												size="20"
												class="text-success cursor-pointer mr-50 icon-grid"
											/>
										</template>
										<!-- @click="orderConfirmado(item)" -->
										<template v-else>
											<FeatherIcon
												@click="orderConfirmado(item)"
												v-b-tooltip.hover
												title="Confirmar"
												v-if="[3].includes(status)"
												icon="CheckSquareIcon"
												size="20"
												class="text-secondary cursor-pointer mr-50 icon-grid"
											/>
										</template>
									</div>
								</b-td>
							</template>
						</b-tr>

						<b-tr v-if="orders.length == 0">
							<b-td colspan="7" class="text-center">
								<p class="mb-0 font-weight-bold">Sin ordenes encontrados</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</div>

		<b-modal
			v-model="viewModalShowById.show"
			:title="viewModalShowById.data && viewModalShowById.data.code"
			size="xl"
			body-class="p-0 m-0"
			hide-footer
		>
			<ShowOrderById :order="viewModalShowById.data" :status="status" @refresh="refresh()" />
		</b-modal>

		<b-modal
			v-model="viewModalChangeStatus.show"
			:title="viewModalChangeStatus.title"
			v-if="viewModalChangeStatus.show"
			hide-footer
		>
			<ChangeStatusOrder
				@refresh="refresh"
				v-if="viewModalChangeStatus.show"
				:order="viewModalChangeStatus.order"
				:status="viewModalChangeStatus.status"
			/>
		</b-modal>

		<b-modal
			v-model="viewModalTracking.show"
			:title="viewModalTracking.title"
			v-if="viewModalTracking.show"
			hide-footer
			size="xmd"
		>
			<div class="card mt-0 mb-0 mx-auto">
				<b-table-simple class="table mb-0">
					<b-thead>
						<b-tr class="text-center">
							<b-th> # </b-th>
							<b-th> Estado </b-th>
							<b-th> Observacion </b-th>
							<b-th> Usuario </b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr class="text-center" v-for="(item, index) in viewModalTracking.data" :key="index">
							<template v-if="viewModalTracking.data.length > 0">
								<b-td class="">
									<p class="mb-0">{{ index + 1 }}</p>
								</b-td>

								<b-td class="">
									<p class="mb-0">{{ getCapitalize(item.status) }}</p>
								</b-td>

								<b-td class="">
									<p class="mb-0">{{ item.observation }}</p>
								</b-td>

								<b-td class="">
									<p class="mb-0">{{ item.created.user }}</p>
									<span>{{ item.created.date | myGlobalDayShort }}</span>
								</b-td>
							</template>
						</b-tr>
						<b-tr v-if="viewModalTracking.data.length == 0">
							<b-td colspan="7" class="text-center">
								<p class="mb-0 font-weight-bold">_ _</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
		</b-modal>
	</div>
</template>

<script>
import orderService from "@/services/order.service";
import ShowOrderById from "./ShowOrderById.vue";
import ChangeStatusOrder from "./ChangeStatusOrder.vue";
export default {
	name: "OrderListTab",
	components: {
		ShowOrderById,
		ChangeStatusOrder,
	},
	props: {
		status: {
			type: Number,
		},
	},

	data() {
		return {
			headTable: [
				{ key: "code", title: "Codigo", visible: true },

				{ key: "observation", title: "Observacion", visible: true },
				{ key: "fecha", title: "Solicitada", visible: true },
				{ key: "fecha", title: "Programada", visible: true },
				{ key: "fecha", title: "Entrega", visible: true },
				{ key: "total_s", title: "Total S/.", visible: true },
				{ key: "total_s", title: "Total $", visible: true },
				{ key: "status", title: "Estado", visible: true },
				{ key: "user", title: "Creado por", visible: true },
				{ key: "actions", title: "Acciones", visible: true },
			],
			orders: [],
			search: null,
			start_page: 1,
			to_page: 1,
			total_data: 1,
			current_page: 1,
			perpage: 50,
			viewModalShowById: {
				show: false,
				data: null,
			},
			viewModalChangeStatus: {
				show: false,
				title: null,
				order: null,
			},
			item: {},
			viewModalTracking: {
				show: false,
				title: "Tracking de estados",
				data: [],
			},
		};
	},
	async mounted() {
		await this.init();
	},
	computed: {
		textStatus: () => (status_id) => {
			let text = "";
			switch (status_id) {
				case 6:
					text = "Guardado";
					break;
				case 1:
					text = "Solicitado";
					break;
				default:
					break;
			}
			return text;
		},
		showStatus() {
			return this.status == 1 && !this.isAdministrator;
		},
	},
	methods: {
		async init() {
			this.isPreloading();
			const {
				data: { data, from, last_page, to, total },
			} = await orderService.getOrder({
				page: this.current_page,
				perpage: this.perpage,
				search: this.search,
				status_id: this.status,
			});
			this.orders = data;

			this.start_page = from;
			this.to_page = to;
			this.total_data = total;
			this.isPreloading(false);

			this.$emit("refresh");
		},
		async refresh() {
			await this.init();
			this.viewModalChangeStatus.show = false;
			//this.viewModalShowById.show = false;
			this.$emit("refresh");
		},
		async viewModalTrackingFunction(item) {
			this.viewModalTracking.show = true;
			this.viewModalTracking.id = item.id;
			this.viewModalTracking.title = item.code;
			this.isPreloading();
			const { data } = await orderService.getTrackingStatus({ id: item.id });
			this.viewModalTracking.data = data;
			this.isPreloading(false);
		},
		async finishedOrder(item) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const resp = await orderService.changeStatus({
				id: item.id,
				status: 3,
			});
			this.isPreloading(false);
			await this.init();
			this.$emit("refresh");
		},
		async cancelOrder(item) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			const resp = await orderService.changeStatus({
				id: item.id,
				status: 4,
			});
			this.isPreloading(false);
			await this.init();
		},
		async orderConfirmado(item) {
			if (!this.isSupervisor) {
				return this.showToast("warning", "top-right", "Orden de compra", "WarningIcon", "No permitido");
			}
			this.viewModalChangeStatus.show = true;
			this.viewModalChangeStatus.order = item;
			this.viewModalChangeStatus.status = 5;
			this.viewModalChangeStatus.title = item.code + "(Confirmar)";

			// const { isConfirmed } = await this.showConfirmSwal();
			// if (!isConfirmed) return;
			// const resp = await orderService.changeStatus({
			// 	id: item.id,
			// 	status: 5,
			// });
			// await this.init();
			// this.$emit("refresh");
		},
		redirectCart(order_id) {
			this.$router.push({
				name: `orders-cart-sr`,
				query: {
					order_id: order_id,
				},
			});
		},
		getCapitalize(text) {
			console.log("text", text);
			if (text) {
				return text[0].toUpperCase() + text.substring(1);
			} else {
				return "";
			}
		},
	},
	watch: {
		async current_page() {
			await this.init();
		},
	},
};
</script>

<style lang="scss" scoped>
.icon-grid {
	font-size: 30px;
}
.xt-search-input__search {
	width: 100% !important;
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 900px) {
		width: 40% !important;
		flex-direction: row;
	}
}
</style>
