<template>
	<div>
		<validation-observer ref="form">
			<div class="container-view-update-corrales">
				<b-row>
					<b-col cols="12" xl="12" class="mb-1">
						<ValidationProvider rules="required" v-slot="{ errors }" class="w-100">
							<div class="d-flex align-items-center">
								<b-form-group
									:label="text_field(status)"
									label-for=""
									:invalid-feedback="errors[0]"
									:state="errors[0] ? false : null"
									class="mb-0"
								>
									<flat-pickr
										v-model="orderData[text_date(status)]"
										class="form-control bg-white"
										placeholder="Seleccionar fecha"
										:class="errors[0] ? 'is-invalid' : ''"
									/>
								</b-form-group>
							</div>
						</ValidationProvider>
					</b-col>
				</b-row>

				<b-row class="mb-1">
					<b-col cols="12" md="12">
						<!-- :invalid-feedback="errors[0]"
								:state="errors[0] ? false : null" -->
						<b-form-group label="Observaciones" label-for="comment" class="mb-0">
							<b-form-textarea
								v-model="orderData.observation"
								placeholder="Agregue una observacion (opcional)"
								rows="2"
								max-rows="3"
							></b-form-textarea>
						</b-form-group>
					</b-col>
					<b-col cols="12" md="6"> </b-col>
				</b-row>
			</div>
		</validation-observer>

		<div class="w-100 d-flex justify-content-end">
			<!-- <button class="btn btn-secondary mr-1">Limpiar campos</button> -->

			<button @click="changeStatus" class="btn btn-primary">Guardar</button>
		</div>
	</div>
</template>

<script>
import orderService from "@/services/order.service";

export default {
	name: "ChangeStatusOrder",
	props: {
		order: {
			type: Object,
			default: () => {},
		},
		status: {
			type: Number,
		},
	},
	data() {
		return {
			orderData: {
				reference_day: null,
				date_delivered: null,
				observation: null,
				id: null,
				date: null,
			},
		};
	},
	mounted() {
		this.orderData = { ...this.order, observation: null, reference_day: null, date_delivered: null };
		this.orderData[this.text_date(this.status)] = new Date();
	},
	computed: {
		text_date: () => (status) => {
			if (status == 2) {
				return "date_delivery";
			} else if (status == 3) {
				return "date_delivered";
			}
		},
		text_field: () => (status) => {
			if(status == 2) {
				return 'Fecha programada'
			} else if(status == 3) {
				return "Fecha de entrega"
			} else if (status == 5) {
				return "Fecha confirmada"
			}
		} 
	},
	methods: {
		async changeStatus() {
			if (!(await this.$refs.form.validate())) {
				this.showToast(
					"danger",
					"top-right",
					"Campos Incompletos",
					"AlertCircleIcon",
					"Faltan campos por llenar"
				);
				return;
			}
			console.log("status", this.status, this.orderData);
			const resp = await orderService.changeStatus({
				date_delivery: this.orderData.date_delivery,
				observation: this.orderData.observation,
				date_delivered: this.orderData.date_delivered,
				status: this.status,
				id: this.orderData.id,
				date: this.orderData[this.text_date(this.status)],
			});

			this.$emit("refresh");
		},
	},
};
</script>
