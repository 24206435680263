<template>
	<div v-if="load">
		<div class="card flex-column flex-md-row justify-content-between p-2 container-uls-encaste-chicks">
			<ul
				class="d-flex flex-column flex-md-row nav nav-tabs align-items-center gap-x-2 mb-0 w-100"
				role="tabslits"
			>
				<b-nav-item
					v-for="(tab, index) in countersOrders"
					link-classes="px-1 justify-content-between"
					class="x-tab-width"
					href="#"
					:key="`${index}-index`"
					:active="tabIndex == index"
					@click="tabIndex = index"
				>
					<span>{{ getStatusText(tab.id) }}</span>
					<b-badge v-if="tab.show && tab.count > 0" pill variant="danger" class="ml-1">
						{{ tab.count }}
					</b-badge>
				</b-nav-item>
			</ul>
			<!-- href -->
			<b-button class="btn-add-order" variant="primary" to="/ordenes/carrito" v-if="isSupervisor">Nuevo</b-button>
		</div>

		<b-tabs
			content-class="mt-2"
			nav-wrapper-class="card pt-1 pr-1 pl-1 d-none"
			nav-class="align-items-center"
			v-model="tabIndex"
			lazy
		>
			<b-tab v-for="(tab, index) in countersOrders" :key="index">
				<OrderListTab @refresh="initCounter" :status="tab.id" />
			</b-tab>
		</b-tabs>

		<div></div>
	</div>
</template>

<script>
import orderService from "@/services/order.service";
import OrderListTab from "./OrderListTab.vue";
// pendiente, enviad

export default {
	name: "OrderList",
	components: {
		OrderListTab,
	},
	data() {
		return {
			countersOrders: [],
			tabIndex: 0,
			load: false,
		};
	},
	async mounted() {
		await this.initCounter();
	},
	methods: {
		async initCounter() {
			const { data, message } = await orderService.getCounterOrder();
			this.countersOrders = data;
			// this.isPreloading(false);
			this.load = true;
		},
		getStatusText(id) {
			switch (id) {
				case 1:
					return "Solicitados";
				case 2:
					return "En proceso";
				case 3:
					return "Entregados";
				case 4:
					return "Anulados";
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.btn-add-order {
	@media screen and (min-width: 700px) {
		width: 200px !important;
	}
}
</style>
