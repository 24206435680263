<template>
	<div class="p-2">
		<div class="card mb-0">
			<div class="table-responsive mb-0">
				<b-table-simple sticky-header class="table mb-0" :style="{ maxHeight: '70vh' }">
					<b-thead>
						<b-tr class="text-center">
							<b-th v-for="(title, key) in headTable" :key="key">
								<template v-if="title.visible">
									<div class="d-flex align-items-center justify-content-center cursor-pointer">
										<p class="mb-0">{{ title.title }}</p>
									</div>
								</template>
							</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr class="text-center" v-for="(item, index) in products" :key="index">
							<template v-if="products.length > 0">
								<b-td class="">
									<p class="mb-0">{{ item.name }}</p>
								</b-td>

								<b-td class="">
									<p class="mb-0">{{ item.category }}</p>
								</b-td>

								<b-td class="">
									<p class="mb-0">{{ parseInt(item.quantity) }}</p>
								</b-td>

								<!-- <b-td class="">
									<p class="mb-0">{{ item.um_code }}</p>
								</b-td> -->

								<b-td class="">
									<div
										v-if="item.edit_price && parseFloat(item.price_s) > 0"
										class="d-flex align-items-center justify-content-center"
									>
										<money
											v-bind="money"
											v-model="item.price_s"
											class="form-control mr-50"
											placeholder="Agregar precio"
											:style="{ width: '90px', textAlign: 'center' }"
											@key.
										></money>
										<FeatherIcon
											@click="item.edit_price = false"
											icon="XIcon"
											size="20"
											class="text-danger cursor-pointer"
										/>
										<FeatherIcon
											@click="updatePrice(item, index)"
											icon="SaveIcon"
											size="20"
											class="text-primary cursor-pointer"
										/>
									</div>
									<div v-else class="d-flex align-items-center justify-content-center">
										<p class="mb-0 mr-1">
											{{ `${parseFloat(item.price_s).toFixed(2)}` }}
										</p>
										<FeatherIcon
											v-if="isAdministrator && status == 1 && parseFloat(item.price_s) > 0"
											@click="item.edit_price = true"
											icon="EditIcon"
											size="20"
											class="text-primary cursor-pointer"
										/>
									</div>
								</b-td>

								<b-td class="">
									<div
										v-if="item.edit_price && parseFloat(item.price_d) > 0"
										class="d-flex align-items-center justify-content-center"
									>
										<money
											v-bind="money"
											v-model="item.price_d"
											class="form-control mr-50"
											placeholder="Agregar precio"
											:style="{ width: '90px', textAlign: 'center' }"
											@key.
										></money>
										<FeatherIcon
											@click="item.edit_price = false"
											icon="XIcon"
											size="20"
											class="text-danger cursor-pointer"
										/>
										<FeatherIcon
											@click="updatePrice(item, index)"
											icon="SaveIcon"
											size="20"
											class="text-primary cursor-pointer"
										/>
									</div>
									<div v-else class="d-flex align-items-center justify-content-center">
										<p class="mb-0 mr-1">
											{{ `${parseFloat(item.price_d).toFixed(2)}` }}
										</p>
										<FeatherIcon
											v-if="isAdministrator && status == 1 && parseFloat(item.price_d) > 0"
											@click="item.edit_price = true"
											icon="EditIcon"
											size="20"
											class="text-primary cursor-pointer"
										/>
									</div>
								</b-td>

								<b-td class="">
									<p class="mb-0" v-if="item.currency == 'PEN'">
										{{
											`${(
												parseFloat(item.price_d) * parseInt(item.quantity) +
												parseFloat(item.price_s) * parseInt(item.quantity)
											).toFixed(2)}`
										}}
									</p>
									<p v-else class="mb-0">0.00</p>
								</b-td>

								<b-td class="">
									<p class="mb-0" v-if="item.currency == 'USD'">
										{{
											`${(
												parseFloat(item.price_d) * parseInt(item.quantity) +
												parseFloat(item.price_s) * parseInt(item.quantity)
											).toFixed(2)}`
										}}
									</p>
									<p v-else class="mb-0">0.00</p>
								</b-td>
							</template>
						</b-tr>

						<b-tr v-if="products.length == 0">
							<b-td colspan="7" class="text-center">
								<p class="mb-0 font-weight-bold">Sin ordenes encontrados</p>
							</b-td>
						</b-tr>
					</b-tbody>

					<b-tfoot>
						<b-tr class="text-center">
							<b-th></b-th>
							<b-th></b-th>
							<b-th></b-th>
							<b-th></b-th>
							<b-th>Total</b-th>

							<b-th>
								{{ sumImporte(products, "price_s").toFixed(2) }}
							</b-th>
							<b-th>
								{{ sumImporte(products, "price_d").toFixed(2) }}
							</b-th>
						</b-tr>
					</b-tfoot>
				</b-table-simple>
			</div>
		</div>
	</div>
</template>

<script>
import orderService from "@/services/order.service";
import { Money } from "v-money";

export default {
	name: "ShowOrderById",
	components: {
		Money,
	},
	props: {
		order: {
			type: Object,
		},
		status: {
			type: Number,
		},
		code: {
			type: String,
		},
	},
	data() {
		return {
			headTable: [
				{ key: "name", title: "Nombre", visible: true },
				{ key: "category", title: "Categoria", visible: true },
				{ key: "quantity", title: "Cantidad", visible: true },
				// { key: "um", title: "U/M", visible: true },
				{ key: "price", title: "Precio S/.", visible: true },
				{ key: "price", title: "Precio $", visible: true },
				{ key: "total", title: "Importe S/.", visible: true },
				{ key: "total", title: "Importe $", visible: true },
			],
			products: [],
			money: {
				decimal: ",",
				thousands: ".",
				prefix: "",
				suffix: "",
				precision: 2,
				masked: false,
			},
		};
	},
	computed: {
		sumImporte: () => (items, key) => {
			return items.reduce(function (previousValue, currentValue) {
				return previousValue + parseFloat(currentValue[key]) * parseInt(currentValue.quantity);
			}, 0);
		},
	},
	async mounted() {
		this.isPreloading();
		const { data } = await orderService.showOrderById({ id: this.order.id });
		this.products = data.map((it) => ({ ...it, edit_price: false, price: parseFloat(it.price) }));
		this.isPreloading(false);
	},
	methods: {
		async updatePrice(e, index) {
			const { isConfirmed } = await this.showConfirmSwal();
			if (!isConfirmed) return;
			this.isPreloading();
			let products = [];
			products.push({ id: e.id, item_id: e.item_id, price: e.price_s > 0 ? e.price_s : e.price_d });
			const { message, status } = await orderService.updatePriceDetails({
				products,
			});
			this.products[index].edit_price = false;
			this.showToast("success", "top-right", "Compras", "SuccessIcon", message);
			this.isPreloading(false);
			this.$emit("refresh");
		},
	},
};
</script>
